:root {
  --color1: #808080;
  --color2: #185494;
  --color3: #f7f7f7;
  --color4: #f3f3f3;
  --color5: #ebebeb;
  --color6: #231f20;
  --color7: rgba(77, 77, 77, 0.12);
  --color8: #4d4d4d;
  --color9: #185494;
}
/* 
rgb(59, 139, 110);
 rgb(137, 217, 156);
 rgb(22, 71, 114);
 */
* {
  font-family: Montserrat, sans-serif;
}
html {
  scroll-behavior: smooth;
}
/* common css */

.trans_anim {
  transform: translateX(-30%); 
  animation: slideIn 0.6s ease-in forwards; 
}
.trans_anim_rev {
  transform: translateX(30%); 
  animation: slideOut 0.6s ease-in forwards; 
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color6);
  outline: none;
  cursor: pointer;
}

/* input[type="radio"]:checked {
  color: #fff;
} */

.bg_effect {
  border-radius: 15%/75%;
  background-color: var(--color3);
}
.text_blur {
  opacity: 0.5;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.loader {
  border: 16px solid var(--color4);
  border-radius: 50%;
  border-top: 16px solid var(--color1);
  border-bottom: 16px solid var(--color1);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 40px auto;
}


@keyframes slideIn {
  from { transform: translateX(-30%); opacity: 0; } 
  to { transform: translateX(0); opacity: 1;} 
}

@keyframes slideOut {
  from { transform: translateX(30%); opacity: 0;}
  to { transform: translateX(0); opacity: 1;}
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* app css */
.heading_text {
  font-size: 32px;
  font-weight: 700;
  max-width: 550px;
}
.heading_text_sm {
  font-size: 22px;
  font-weight: 400;
  max-width: 550px;
}
.logo_part img {
  width: 400px;
}
.progress_bar {
  position: relative;
}
.progress_bar_sm {
  position: relative;
}
.progress_bar_div {
  max-width: 1200px;
  margin: auto;
}
.steps_lg {
  display: flex;
  justify-content: center;
}
.steps_sm {
  display: flex;
  justify-content: center;
}
.step_lg {
  width: 420px;
  height: 130px;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.step_sm {
  width: 360px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.step_lg .step_lg_img,
.step_sm_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 2;
}
.step_lg svg,
.step_sm svg {
  width: 25px;
  display: block;
  height: 25px;
  color: #f7f7f7;
  margin: 8px 0 0 8px;
}
/* .step_lg .step_lg_img img,
.step_sm_img img {
  width: 25px;
  height: 25px;
  margin: 8px 0 0 8px;
  border-radius: 50%;
} */
.step_lg .step_lg_text h3 {
  font-size: 17px;
}
.step_sm .step_sm_text h3 {
  font-size: 17px;
}
.back_border {
  background: var(--color7);
  height: 2px;
  position: absolute;
  top: 40px;
  max-width: 1200px;
  width: 100%;
  z-index: 1;
}
.back_border_sm {
  background: var(--color7);
  height: 2px;
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: 1;
}

.functionality_top {
  margin: auto;
  width: 70%;
}
h2 {
  font-weight: 700;
}
.master_input {
  width: 100%;
  height: 40px;
  color: var(--color8);
  background-color: var(--color5);
  border: none;
  outline: none;
  border-radius: 7px;
  padding-left: 15px;
  font-size: 18px;
  letter-spacing: 1px;
}
.dropdown {
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
}
.dropdown_box {
  display: flex;
  flex-direction: column;
}
.dropdown span {
  height: 40px;
  background-color: var(--color3);
  color: var(--color8);
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px 0px 0 15px;
  font-size: 18px;
  margin: 3px 0;
  font-weight: 600;
  cursor: pointer;
}

.car_company_name {
  width: 80%;
  display: flex;
  height: 45px;
  background-color: var(--color1);
  color: var(--color3);
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
.make_btn {
  font-size: 14px;
  margin: 0 25px 0 0;
  width: 50px;
}
.car_company_name span {
  border: none;
  outline: none;
  background: transparent;
  display: inline-block;
}
.car_company_name svg {
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 5px;
  color: var(--color3);
}
.car_company_name svg:hover {
  color: var(--color1);
  cursor: pointer;
}
.car_company_name div {
  display: flex;
  width: 80%;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 0 0 15px;
}
.next_btn {
  background: #18549459;
  color: var(--color2);
  cursor: pointer;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 7px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
}
.form_lable {
  font-size: 22px;
  opacity: 0.7;
}
.form_lable_span {
  width: 180px;
  font-size: 18px;
  opacity: 0.7;
}
.form_lable_radio {
  font-size: 18px;
  opacity: 0.7;
}
.a_terms {
  font-size: 19px;
  font-weight: 500;
  cursor: pointer;
}
.check_box {
  cursor: pointer;
}
.check_box input {
  cursor: pointer;
}
.check_box > span {
  font-size: 1rem;
}
.error_message {
  font-size: 16px;
  margin-bottom: -10px;
  color: #dd4b39;
}
