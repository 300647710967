@media only screen and (max-width: 600px) {
  .logo_part img {
    width: 300px;
  }
  .progress_bar {
    display: none;
  }
  .car_company_name {
    width: 90%;
  }
  .btns {
    float: left;
  }
}
@media only screen and (min-width: 768px) {
  .btns {
    float: right;
  }
}
@media only screen and (min-width: 600px) {
  .progress_bar_sm {
    display: none;
  }
}
